import axios, { AxiosError } from 'axios';
import { QIT_API_BASE_URL } from 'app/constants/env';
import { signOutPath } from 'app/constants/url/shared';
import { forbiddenErrorPath as forbiddenErrorPathPatient } from 'app/constants/url/patient';
import { calendarPath, forbiddenErrorPath } from 'app/constants/url/therapist';
import { getStoredRefreshToken, setStoredRefreshToken, setStoredToken } from 'data';
import { queryClient } from 'data/react-query/queryClient';
import { useHasRole } from 'app/utils/hooks';
import { RoleEnum } from 'data/enums';
import { forbiddenErrorCode } from 'app/constants/app';
import { matchPath } from 'react-router';
import { storeTargetPageAndLogout } from './utils';

const jwtError = {
  code: 401,
  messages: ['expired jwt token', 'jwt token not found', 'invalid jwt token'],
};

let isTokenAfterJWTErrorRefreshing = false;

const tryToRefreshTokenAgain = async (error: AxiosError) => {
  const refreshToken = getStoredRefreshToken();

  // if we already refreshing AC -> block current run
  if (isTokenAfterJWTErrorRefreshing) return;

  if (refreshToken) {
    try {
      isTokenAfterJWTErrorRefreshing = true;
      const refreshResponse = await axios.post(`${error.config?.baseURL || QIT_API_BASE_URL}/tokens/refresh`, {
        refreshToken,
      });
      // store new tokens in LS
      setStoredToken(refreshResponse.data.token);
      setStoredRefreshToken(refreshResponse.data.refreshToken.value);
      // after successful refresh repeat requests
      queryClient.resetQueries();
    } catch (err) {
      // if AC refreshing fail
      storeTargetPageAndLogout();
    } finally {
      isTokenAfterJWTErrorRefreshing = false;
    }
  } else {
    window.location.replace(signOutPath);
  }
};

export const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const isClient = useHasRole(RoleEnum.ROLE_CLIENT);
  const isCalendar = !!matchPath(window.location.pathname, { path: calendarPath });

  if (error?.response) {
    const {
      response: { data: errorData },
    } = error;

    const isDocumentForbidden = error.config?.url?.includes('url/document');

    // JWT error handling
    if (
      Number(errorData?.code) === jwtError.code &&
      jwtError.messages.includes(String(errorData?.message).toLowerCase())
    ) {
      tryToRefreshTokenAgain(error);
    }

    if (errorData?.code === forbiddenErrorCode && !isCalendar && !isDocumentForbidden) {
      window.location.replace(isClient ? forbiddenErrorPathPatient : forbiddenErrorPath);
    }

    if (errorData) {
      return Promise.reject(error);
    }
  }

  if (error?.message !== 'Cancel request') {
    return Promise.reject(error.toJSON()); // show full error to see more details
  }
  return Promise.reject(new Error('Something went wrong'));
};
