import { portfolioKeys } from 'data/utils/hookKeys';
import { PortfolioSection, ArrayResponseType, BaseFetchListParams, AddPortfolioSectionValues } from 'data/types';
import { UseMutationResult, useQueryClient, useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getStoredUserId } from 'data/utils/userStorage';
import { fetchLimit } from 'app/constants/app';
import { Portfolio } from 'app/components/app/therapist/profile/hooks/usePortfolioPage';
import queryString from 'qs';
import { AxiosError } from 'axios';
import * as api from '../actions-query';
import { PortfolioType } from 'data/enums';

const portfolioFallback: Portfolio = {
  id: '',
  createdAt: '',
  updatedAt: '',
  type: PortfolioType.PRIVATE,
};

interface UsePortfolio {
  portfolio: Portfolio;
  isPortfolioLoading: boolean;
}

export const usePortfolio = (): UsePortfolio => {
  const userId = getStoredUserId();

  const { data = portfolioFallback, isPending: isLoading } = useQuery<Portfolio, AxiosError>({
    queryKey: portfolioKeys.detail(userId),
    queryFn: () => api.fetchUserPortfolio(userId),
    enabled: !!userId,
  });

  return { portfolio: data, isPortfolioLoading: isLoading };
};

const defaultSectionsSearchParams: BaseFetchListParams = {
  limit: fetchLimit,
  offset: 0,
};

export const usePortfolioSections = (
  portfolioId: string,
  fetchParams = defaultSectionsSearchParams,
): UseQueryResult<ArrayResponseType<PortfolioSection>, AxiosError> => {
  const query = queryString.stringify(fetchParams);
  return useQuery({
    queryKey: portfolioKeys.list(query),
    queryFn: () => api.fetchPortfolioSections(portfolioId, query),
    enabled: !!portfolioId,
  });
};

export const useCreatePortfolioSection = (): UseMutationResult<
  PortfolioSection,
  AxiosError,
  { portfolioId: string; values: AddPortfolioSectionValues },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ portfolioId, values }) => api.createPortfolioSection(portfolioId, values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: portfolioKeys.lists() });
    },
  });
};

export const useEditPortfolioSection = (): UseMutationResult<
  PortfolioSection,
  AxiosError,
  { sectionId: string; values: Partial<AddPortfolioSectionValues> },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ sectionId, values }) => api.editPortfolioSection(sectionId, values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: portfolioKeys.lists() });
    },
  });
};

export const useDeletePortfolioSection = (): UseMutationResult<void, AxiosError, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (sectionId) => api.deletePortfolioSection(sectionId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: portfolioKeys.lists() });
    },
  });
};

export const useChangePortfolioAccess = (): UseMutationResult<
  Portfolio,
  AxiosError,
  { portfolioId: string; body: { isPublic: boolean } },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ portfolioId, body }) => api.changePortfolioAccess(portfolioId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: portfolioKeys.details() });
    },
  });
};

export const usePortfolioPublic = (token: string): UsePortfolio => {
  const { data = portfolioFallback, isPending: isLoading } = useQuery<Portfolio, AxiosError>({
    queryKey: portfolioKeys.detail(token),
    queryFn: () => api.fetchUserPortfolioPublic(token),
    enabled: !!token,
  });

  return { portfolio: data, isPortfolioLoading: isLoading };
};

export const usePortfolioPublicSections = (
  portfolioToken: string,
  fetchParams = defaultSectionsSearchParams,
): UseQueryResult<ArrayResponseType<PortfolioSection>, AxiosError> => {
  const query = queryString.stringify(fetchParams);
  return useQuery({
    queryKey: portfolioKeys.publicList(query),
    queryFn: () => api.fetchPortfolioPublicSections(portfolioToken, query),
    enabled: !!portfolioToken,
  });
};
