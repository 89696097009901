import {
  Record,
  DocumentFile,
  CreateRecordFields,
  EditRecordFields,
  BaseFetchListParams,
  ArrayResponseType,
  DocumentLinkFields,
  EditRecordStatus,
  BasicRecord,
  RecordIntake,
} from 'data/types';
import { recordKeys, userKeys } from 'data/utils/hookKeys';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { RecordIntakesTableAccessor } from 'app/components/app/therapist/records/page/record/registration/components/intakes-table/constants';

import queryString from 'qs';
import { AxiosError } from 'axios';

import { ClientSortKeys, FilePurpose, RecordRoles, SortDirection } from 'data/enums';
import { getStoredUserId } from 'data/utils/userStorage';
import * as api from '../actions-query';

export enum FilesSortKeys {
  title = 'title',
  owner = 'owner',
  createdAt = 'createdAt',
}
export interface IRecordsSearchParams extends BaseFetchListParams {
  sort: {
    [key in ClientSortKeys]?: SortDirection;
  };
  filters: {
    search?: string | null;
    active?: number;
    roles?: RecordRoles[];
  };
}

export interface IRecordsDocumentsParams {
  offset?: number;
  limit?: number;
  sort?: {
    [key: string]: SortDirection;
  };
  filters?: {
    search?: string | null;
  };
  purpose?: FilePurpose;
}

interface RecordIntakesParams {
  sort?: {
    [key in RecordIntakesTableAccessor]?: SortDirection;
  };
  limit?: number;
  offset?: number;
}

export const defaultSearchParams: IRecordsSearchParams = {
  sort: {
    [ClientSortKeys.LAST_NAME]: SortDirection.asc,
  },
  limit: 12,
  offset: 0,
  filters: {
    active: 1,
  },
};

export const defaultDocumentSearchParams: IRecordsDocumentsParams = {
  sort: {
    title: SortDirection.asc,
  },
  limit: 12,
  offset: 0,
};

// eslint-disable-next-line default-param-last
export const useRecords = ({
  searchParams = defaultSearchParams,
  isOrgAdmin = false,
  disabled = false,
  isClient = false,
}: {
  searchParams?: IRecordsSearchParams;
  isOrgAdmin?: boolean;
  disabled?: boolean;
  isClient?: boolean;
}) => {
  const query = queryString.stringify(searchParams);

  const userId = getStoredUserId();
  return useQuery<ArrayResponseType<Record>, AxiosError>({
    queryKey: recordKeys.list(query, userId),
    queryFn: () => api.fetchRecords(userId, query, isClient),
    enabled: !isOrgAdmin && !disabled,
  });
};

export const useBasicRecords = (
  searchParams: IRecordsSearchParams = defaultSearchParams,
  isOrgAdmin = false,
  disabled = false,
) => {
  const query = queryString.stringify(searchParams);

  const userId = getStoredUserId();
  return useQuery<ArrayResponseType<BasicRecord>, AxiosError>({
    queryKey: recordKeys.basic(query, userId),
    queryFn: () => api.fetchBasicRecords(userId, query),
    enabled: !isOrgAdmin && !disabled,
  });
};

export const useOrganisationRecords = (orgId: string, searchParams: IRecordsSearchParams, isOrgAdmin?: boolean) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<Record>, AxiosError>({
    queryKey: recordKeys.organization(orgId, query),
    queryFn: () => api.fetchOrganisationRecords(orgId, query),
    enabled: isOrgAdmin,
  });
};

export const useRecord = ({ recordId, options, isAffiliate }: { recordId?: string; options?; isAffiliate?: boolean }) =>
  useQuery<Record, AxiosError>({
    queryKey: isAffiliate ? recordKeys.affiliate(recordId) : recordKeys.detail(recordId),
    queryFn: () => api.fetchRecord({ recordId, isAffiliate }),
    enabled: !!recordId,
    refetchOnMount: false,
    ...options,
  });

export const useRecordDocuments = (recordId: string, searchParams?: IRecordsDocumentsParams) => {
  const userId = getStoredUserId();
  const query = queryString.stringify(searchParams || defaultDocumentSearchParams);
  return useQuery<ArrayResponseType<DocumentFile>, AxiosError>({
    queryKey: recordKeys.documents(query, recordId, userId),
    queryFn: () => api.fetchRecordDocuments(recordId, userId, query),
  });
};

export const useLinkDocument = (
  searchParams?: IRecordsDocumentsParams,
): UseMutationResult<unknown, any, { recordId: string; fields: DocumentLinkFields }, unknown> => {
  const queryClient = useQueryClient();
  const userId = getStoredUserId();
  return useMutation({
    mutationFn: ({ recordId, fields }) => api.linkDocumentToRecord(recordId, userId, fields),
    onSuccess: (_, variables) => {
      const query = queryString.stringify(searchParams || defaultDocumentSearchParams);
      queryClient.invalidateQueries({ queryKey: recordKeys.documents(query, variables.recordId, userId) });
    },
  });
};

export const useEditRecord = (): UseMutationResult<
  unknown,
  any,
  { recordId: string; fields: EditRecordFields },
  unknown
> => {
  const queryClient = useQueryClient();

  const userId = getStoredUserId();
  return useMutation({
    mutationFn: ({ recordId, fields }) => api.editRecord(recordId, fields),
    onSuccess: (_, variables) => {
      const query = queryString.stringify(defaultSearchParams);
      queryClient.invalidateQueries({ queryKey: recordKeys.list(query, userId) });
      queryClient.invalidateQueries({ queryKey: recordKeys.detail(variables.recordId) });
    },
  });
};

export const useEditRecordStatus = (): UseMutationResult<
  unknown,
  any,
  { recordId: string; fields: EditRecordStatus },
  unknown
> => {
  const queryClient = useQueryClient();

  const userId = getStoredUserId();
  return useMutation({
    mutationFn: ({ recordId, fields }) => api.editRecordStatus(recordId, fields),
    onSuccess: (_, variables) => {
      const query = queryString.stringify(defaultSearchParams);
      queryClient.invalidateQueries({ queryKey: recordKeys.list(query, userId) });
      queryClient.invalidateQueries({ queryKey: recordKeys.detail(variables.recordId) });
    },
  });
};

export const useDeleteRecord = (userId: string): UseMutationResult<unknown, any, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (recordId) => api.deleteRecord(recordId),
    onSuccess: () => {
      const query = queryString.stringify(defaultSearchParams);
      queryClient.invalidateQueries({ queryKey: recordKeys.list(query, userId) });
      queryClient.invalidateQueries({ queryKey: userKeys.profile() });
    },
  });
};

export const useCreateRecord = (): UseMutationResult<
  unknown,
  any,
  { therapistId: string; fields: CreateRecordFields },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ therapistId, fields }) => api.createRecord(therapistId, fields),
    onSuccess: (_, variables) => {
      const query = queryString.stringify(defaultSearchParams);
      queryClient.invalidateQueries({ queryKey: recordKeys.list(query, variables.therapistId) });
    },
  });
};

export const useRecordRegistrations = (recordId: string, params?: RecordIntakesParams) => {
  const query = queryString.stringify(params);
  return useQuery<ArrayResponseType<RecordIntake>, AxiosError>({
    queryKey: recordKeys.registrations(recordId, query),
    queryFn: () => api.fetchRecordRegistrations(recordId, query),
    enabled: !!recordId,
  });
};

export const useExportAllRecords = (queryParams?: { active?: number }) => {
  const userId = getStoredUserId();
  const query = queryString.stringify(queryParams);
  return useMutation({
    mutationFn: () => api.exportAllRecords(userId, query),
    onSuccess: (data: Blob) => {
      // Create a URL for the blob
      const url = window.URL.createObjectURL(data);
      // Create temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `records-export-${new Date().toISOString()}.xlsx`);
      // Append to body, click and remove
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      // Clean up the URL
      window.URL.revokeObjectURL(url);
    },
  });
};
