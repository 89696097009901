import React from 'react';
import { ContentEditable as LexicalContentEditable } from '@lexical/react/LexicalContentEditable';
import cx from 'classnames';
import s from './styles.module.scss';

type ContentEditableProps = {
  onRef: (_editorRef: HTMLDivElement) => void;
  dataCy?: string;
  preview?: boolean;
  contentEditableClassName?: string;
};

export const ContentEditable = ({ onRef, dataCy, preview, contentEditableClassName }: ContentEditableProps) => (
  <div ref={onRef} data-cy={dataCy}>
    <LexicalContentEditable
      id="editor"
      contentEditable={false}
      className={cx(s.editor, preview && s.editorPreview, contentEditableClassName)}
    />
  </div>
);
