import { MODE, PROD, QIT_SENTRY_DSN } from 'app/constants/env';
import * as Sentry from '@sentry/react';
const errorsToReload =
  /Loading chunk [\d]+ failed|Loading CSS chunk [\d]+ failed|Failed to fetch dynamically imported module|Unable to preload CSS for|error loading dynamically imported module/i;
// comment hard reload function till we find out the problem
// async function handleHardReload(url: string) {
//   await fetch(url, {
//     headers: {
//       Pragma: 'no-cache',
//       Expires: '-1',
//       'Cache-Control': 'no-cache',
//     },
//   });
//   window.location.reload();
// }
export const init = () => {
  // overwrite console error to catch loading chunk error since it's not catchable via promise
  const origin = console.error;
  console.error = (error) => {
    if (error?.message?.match(errorsToReload)) {
      window.location.reload();
    } else {
      origin(error);
    }
  };
  const IGNORE =
    /Loading chunk [\d]+ failed|Loading CSS chunk [\d]+ failed|Unexpected token '<'|server error|Blocked a frame with origin|not found|aborterror|network error|no error message|network request failed|request failed with status code 401|Non-Error promise rejection captured|ResizeObserver loop completed with undelivered notifications|Failed to fetch dynamically imported module|Unable to preload CSS for|error loading dynamically imported module|Load failed/i;
  // initialize sentry
  if (PROD) {
    Sentry.init({
      dsn: QIT_SENTRY_DSN,
      environment: MODE,
      // This enables automatic instrumentation (highly recommended), but is not
      // necessary for purely manual usage
      integrations: [Sentry.browserTracingIntegration()],
      // new Sentry.Replay()
      // To set a uniform sample rate
      tracesSampleRate: 0.2,
      // This sets the sample rate to be 0%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      // replaysSessionSampleRate: 0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      // replaysOnErrorSampleRate: 1.0,

      beforeSend: (event, hint) => {
        const error = hint.originalException;
        const filterStatuses = [400, 401, 404];

        if (filterStatuses.includes(error?.response?.status) || error?.message?.match(IGNORE)) return null;
        return event;
      },
    });
  }
};
export const captureException = (error, errorInfo) => {
  if (PROD) {
    Sentry.captureException(error, {
      extra: {
        info: errorInfo,
      },
    });
  }
};
