import React, { HTMLProps } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import cx from 'classnames';
import { PUIText } from 'ui';
import theme from 'ui/utils/theme';
import { initialConfig } from './constants';
import { ContentEditable, Placeholder } from './components';
import {
  DisabledPlugin,
  DynamicValuePlugin,
  FloatingLinkEditorPlugin,
  FloatingTextFormatterPlugin,
  HistoryPlugin,
  ImagePlugin,
  InitialHtmlContentPlugin,
  LinkPlugin,
  ListPlugin,
  MarkdownShortcutPlugin,
  OnChangePlugin,
  PasteImagePlugin,
  RichTextPlugin,
  TabIndentationPlugin,
  TableContextMenuPlugin,
  TablePlugin,
  ToolbarPlugin,
} from './plugins';
import './styles.css';
import { extendListItemNode, extendTextNode, validateUrl } from './utils';
import { useEditor } from './hooks';
import TableCellResizerPlugin from './plugins/table-resize';
import { useBlockEmptyLinkNavigation } from './hooks/useBlockEmptyLinkNavigation';

extendListItemNode();
extendTextNode();

type EditorProps = {
  initialHtmlContent?: string;
  variables?: {
    key?: string | undefined;
    text?: string | undefined;
  }[];
  isDocumentBuilder?: boolean;
  onChange?: (htmlString: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  preview?: boolean;
  error?: boolean;
  isImage?: boolean;
  toolbarProps?: HTMLProps<HTMLDivElement>;
  contentEditableClassName?: string;
  'data-cy'?: string;
  editorKey?: string;
  [key: string]: unknown;
};

export const Editor = ({
  editorKey,
  initialHtmlContent,
  variables,
  isDocumentBuilder,
  onChange,
  placeholder,
  disabled = false,
  className,
  preview,
  error,
  toolbarProps,
  contentEditableClassName,
  isImage = true,
  ...props
}: EditorProps) => {
  const { onRef, floatingAnchorElement } = useEditor();
  const { 'data-cy': dataCy, ...rest } = props;
  useBlockEmptyLinkNavigation();

  return (
    <LexicalComposer initialConfig={initialConfig} key={editorKey || 'editorWrapper'}>
      <div
        className={cx('editorWrapper', className, preview && 'preview', error && 'error-state', disabled && 'disabled')}
        {...rest}
      >
        {initialHtmlContent !== undefined && <InitialHtmlContentPlugin initialHtmlContent={initialHtmlContent} />}
        {!preview && (
          <ToolbarPlugin
            variables={variables}
            isDocumentBuilder={isDocumentBuilder}
            toolbarProps={toolbarProps}
            isImage={isImage}
          />
        )}
        <RichTextPlugin
          placeholder={<Placeholder placeholder={placeholder} />}
          ErrorBoundary={LexicalErrorBoundary}
          contentEditable={
            <ContentEditable
              onRef={onRef}
              dataCy={dataCy}
              preview={preview}
              contentEditableClassName={contentEditableClassName}
            />
          }
        />
        <ListPlugin />
        {/* Need it for nested lists + indentation */}
        <TabIndentationPlugin />
        <HistoryPlugin />
        <ImagePlugin />
        <LinkPlugin validateUrl={validateUrl} />
        {floatingAnchorElement && !preview && (
          <>
            <FloatingLinkEditorPlugin floatingAnchorElement={floatingAnchorElement} />
            <TableContextMenuPlugin floatingAnchorElement={floatingAnchorElement} />
            <FloatingTextFormatterPlugin floatingAnchorElement={floatingAnchorElement} />
          </>
        )}
        <TablePlugin hasCellMerge={false} />
        <TableCellResizerPlugin />
        {variables && <DynamicValuePlugin />}
        {onChange && <OnChangePlugin onChange={onChange} />}
        <DisabledPlugin disabled={disabled} />
        <MarkdownShortcutPlugin />
        <PasteImagePlugin />
        {error && (
          <PUIText className="error" color={theme.colors.error} size={theme.typography.sizes.s}>
            {error}
          </PUIText>
        )}
      </div>
    </LexicalComposer>
  );
};
