import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { CalendarType, EditCalendarValues, ImportCalendarFromUrl, PersonalSlot, CalendarEvent } from 'data/types';
import { calendarKeys } from 'data/utils/hookKeys';
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
  useQuery,
  UseQueryResult,
  useQueries,
} from '@tanstack/react-query';
import queryString from 'qs';
import storage from 'app/utils/storage';
import { AxiosError } from 'axios';
import { v4 as uuid } from 'uuid';
import { getStoredUserId } from 'data/utils/userStorage';
import { forbiddenErrorCode, nonFoundErrorCode } from 'app/constants/app';
import { CalendarEventType, CalendarRoles } from 'data/enums';
import { useState } from 'react';

import * as api from '../actions-query';

export type IEventsParams = {
  startDate: Date | null;
  endDate: Date | null;
  calendars?: string[];
};

const now = new Date(Date.now());
const getViewStartDate = (date) => startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
const getViewEndDate = (date) => endOfWeek(endOfMonth(date), { weekStartsOn: 1 });
const errorCodes = [forbiddenErrorCode, nonFoundErrorCode];

export const defaultEventsParams: IEventsParams = {
  startDate: getViewStartDate(now),
  endDate: getViewEndDate(now),
  calendars: storage.getShownCalendarsPreference(),
};

export const useSlot = (slotId: string): UseQueryResult<CalendarEvent, AxiosError> =>
  useQuery<CalendarEvent, AxiosError>({ queryKey: calendarKeys.slot(slotId), queryFn: () => api.fetchSlot(slotId) });

export const useCalendars = (userId: string): UseQueryResult<CalendarType[], AxiosError> =>
  useQuery<CalendarType[], AxiosError>({ queryKey: calendarKeys.all(), queryFn: () => api.fetchCalendars(userId) });

export const useCalendarURL = (calendarId: string): UseQueryResult<string, AxiosError> =>
  useQuery<string, AxiosError>({ queryKey: calendarKeys.export(), queryFn: () => api.fetchCalendarURL(calendarId) });

export const useEvents = (userId: string, searchParams: IEventsParams, isEnabled: boolean) => {
  const query = queryString.stringify(searchParams);
  return useQuery<CalendarEvent[], AxiosError>({
    queryKey: calendarKeys.events(query, userId),
    queryFn: () => api.fetchEvents(userId, query),
    enabled: isEnabled,
  });
};

export const useExternalEvents = (calendarIds: string[], searchParams: IEventsParams, isEnabled: boolean) => {
  const query = queryString.stringify(searchParams);
  return useQueries({
    queries: calendarIds.map((cal) => ({
      queryKey: calendarKeys.externalEvents(query, cal),
      queryFn: () => api.fetchExternalEvents(cal, query),
      enabled: isEnabled,
      onError: (error) => {
        if (errorCodes.includes(error.response.status)) {
          storage.setShownCalendarsPreference(calendarIds.filter((id) => id !== cal));
        }
      },
    })),
  });
};

export const useImportCalendar = (): UseMutationResult<
  CalendarType,
  AxiosError,
  { userId: string; values: ImportCalendarFromUrl },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, values }) => api.importCalendar(userId, values),
    onMutate: async ({ userId, values }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: calendarKeys.all() });

      // Snapshot the previous value
      const previousCalendarsState: CalendarType[] | undefined = queryClient.getQueryData(calendarKeys.all());

      // Optimistically update to the new value
      if (previousCalendarsState) {
        const newCalendar = {
          id: uuid(),
          type: CalendarEventType.EXTERNAL,
          roles: [{ ...values, type: CalendarRoles.OWNER, user: { id: userId } }],
          optimisticData: true,
        };
        queryClient.setQueryData(calendarKeys.all(), [...previousCalendarsState, newCalendar]);
      }

      // Return a context object with the snapshotted value
      return { previousCalendarsState };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_, data, context) => {
      queryClient.setQueryData(calendarKeys.all(), context?.previousCalendarsState);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: calendarKeys.all() });
    },
  });
};

export const useExportCalendar = (): UseMutationResult<string, AxiosError, { calendarId: string }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ calendarId }) => api.exportCalendar(calendarId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: calendarKeys.export() });
    },
  });
};

export const useCreatePersonalSlot = ({
  myCalendarId,
  calendarQuery,
}: {
  myCalendarId?: string;
  calendarQuery?: { startDate: Date; endDate: Date };
}): UseMutationResult<CalendarEvent, AxiosError, { calendarId: string; values: PersonalSlot }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ calendarId, values }) => api.createSlot(calendarId, values),
    onSuccess: (_, variables) => {
      if (myCalendarId) {
        queryClient.invalidateQueries({
          queryKey: calendarKeys.externalEvents(queryString.stringify(calendarQuery), myCalendarId),
        });
      }
    },
  });
};

export const useEditCalendar = (): UseMutationResult<
  CalendarType,
  AxiosError,
  { calendarId: string; values: EditCalendarValues },
  unknown
> => {
  const queryClient = useQueryClient();
  const userId = getStoredUserId();

  return useMutation({
    mutationFn: ({ calendarId, values }) => api.patchCalendar(calendarId, values),
    onMutate: async ({ calendarId, values }) => {
      await queryClient.cancelQueries({ queryKey: calendarKeys.all() });

      const previousCalendars = queryClient.getQueryData(calendarKeys.all());

      queryClient.setQueryData(calendarKeys.all(), (oldCalendars: CalendarType[]) =>
        oldCalendars.map((cal) => {
          if (cal.id !== calendarId) return cal;

          return {
            ...cal,
            roles: cal.roles.map((role) => {
              if (role.user.id !== userId) return role;

              return {
                ...role,
                ...values,
                picture: values.picture ? { ...role.picture, id: values.picture } : role.picture,
              };
            }),
          };
        }),
      );

      return { previousCalendars };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(calendarKeys.all(), context?.previousCalendars);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: calendarKeys.all() });
    },
  });
};

export const useShareCalendar = (): UseMutationResult<
  CalendarType,
  AxiosError,
  { calendarId: string; values: { users: string[] } },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ calendarId, values }) => api.shareCalendar(calendarId, values),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: calendarKeys.all() });
    },
  });
};

export const useRemoveShareCalendarWithUser = (): UseMutationResult<
  CalendarType,
  AxiosError,
  { calendarId: string; userId: string },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ calendarId, userId }) => api.removeShareCalendarWithUser(calendarId, userId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: calendarKeys.all() });
    },
  });
};

export const useEditPersonalSlot = ({
  myCalendarId,
  calendarQuery,
}: {
  myCalendarId: string;
  calendarQuery: { startDate: Date; endDate: Date };
}): UseMutationResult<CalendarEvent, AxiosError, { slotId: string; values: PersonalSlot }, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ slotId, values }) => api.patchSlot(slotId, values),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: calendarKeys.slot(variables.slotId) });
      queryClient.invalidateQueries({
        queryKey: calendarKeys.externalEvents(queryString.stringify(calendarQuery), myCalendarId),
      });
    },
  });
};

export const useDeleteExportCalendar = (): UseMutationResult<void, AxiosError, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (calendarId) => api.deleteExportCalendar(calendarId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: calendarKeys.export() });
    },
  });
};

export const useDeleteExternalCalendar = (): UseMutationResult<void, AxiosError, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (calendarId) => api.deleteExternalCalendar(calendarId),
    onMutate: async (calendarToDeleteId) => {
      await queryClient.cancelQueries({ queryKey: calendarKeys.all() });

      const previousCalendars = queryClient.getQueryData(calendarKeys.all());
      storage.setShownCalendarsPreference(
        storage.getShownCalendarsPreference().filter((id) => id !== calendarToDeleteId),
      );

      queryClient.setQueryData(calendarKeys.all(), (oldCalendars: CalendarType[]) =>
        oldCalendars.filter((cal) => cal.id !== calendarToDeleteId),
      );

      return { previousCalendars };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(calendarKeys.all(), context?.previousCalendars);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: calendarKeys.all() });
    },
  });
};

export const useDeleteSlot = (): UseMutationResult<void, AxiosError, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (slotId: string) => api.deleteSlot(slotId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: calendarKeys.eventsAll() });
    },
  });
};

export const useCalendar = (id: string): UseQueryResult<CalendarType, AxiosError> =>
  useQuery<CalendarType, AxiosError>({
    queryKey: calendarKeys.details(id),
    queryFn: () => api.getExternalCalendar(id),
    enabled: !!id,
  });
