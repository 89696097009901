import { EventUrlEnum } from 'data/enums/EventUrlEnum';

export const baseTherapistPath = '/therapist';

const applyBaseUrl = (url) => `${baseTherapistPath}/${url}`;

// LIBRARY
export const libraryPath = applyBaseUrl('library');

// subscriptions
export const subscriptionsPath = applyBaseUrl('subscriptions');

// Profile
export const profilePath = applyBaseUrl('profile');
export const profilePersonalPath = `${profilePath}/personal`;
export const profilePortfolioPath = `${profilePath}/portfolio`;
export const profilePortfolioPreviewPath = `${profilePath}/portfolio/preview`;
export const profilePortfolioDocumentPreview = (documentId: string) =>
  `${profilePath}/portfolio/document/${documentId}`;

// Records
export const recordsPath = applyBaseUrl('records');
export const recordsCreatePath = `${recordsPath}/create`;
export const recordPath = (recordId: string) => `${recordsPath}/${recordId}`;
export const recordTrajectoriesPath = (recordId: string) => `${recordPath(recordId)}/trajectories`;

export const recordTrajectoryStartSessionPath = (recordId: string) =>
  `${recordTrajectoriesPath(recordId)}/start/session`;
export const recordTrajectoryStartQuestionnairePath = (recordId: string) =>
  `${recordTrajectoriesPath(recordId)}/start/questionnaire`;
export const recordTrajectoryStartQuestionnairePreviewInfoPath = (recordId: string, questionnaire: string) =>
  `${recordTrajectoryStartQuestionnairePath(recordId)}/${questionnaire}/info`;
export const recordTrajectoryStartQuestionnairePreviewQuestionsPath = (recordId: string, questionnaire: string) =>
  `${recordTrajectoryStartQuestionnairePath(recordId)}/${questionnaire}/questions`;
export const recordTrajectoryStartSyncPath = (recordId: string) => `${recordTrajectoriesPath(recordId)}/start/sync`;
export const recordTrajectoryStartModulePath = (recordId: string) => `${recordTrajectoriesPath(recordId)}/start/module`;
export const recordTrajectoryStartExercisePath = (recordId: string) => `${recordTrajectoriesPath(recordId)}/start/task`;

export const recordTrajectoryPath = (recordId: string, trajectoryId: string) =>
  `${recordPath(recordId)}/trajectory/${trajectoryId}`;
export const recordTrajectoryOverviewEndPath = (recordId: string, trajectoryId: string) =>
  `${recordTrajectoryPath(recordId, trajectoryId)}/end`;

export const recordTrajectoryPhysicalInteractionPath = (
  recordId: string,
  trajectoryId: string,
  physicalInteraction: string,
) => `${recordTrajectoryPath(recordId, trajectoryId)}/physical-interaction/${physicalInteraction}`;

export const recordTrajectoryEventPath = (recordId: string, trajectoryId: string, eventType: string, eventId: string) =>
  `${recordTrajectoryPath(recordId, trajectoryId)}/${eventType}/${eventId}`;

export const recordTrajectoryEventDocumentsPath = (
  recordId: string,
  trajectoryId: string,
  eventType: string,
  eventId: string,
  clientId: string,
) => `${recordTrajectoryEventPath(recordId, trajectoryId, eventType, eventId)}/documents/${clientId}`;
export const recordEventDocumentDetailsPath = (recordId: string, eventType: string, documentId: string) =>
  `${recordPath(recordId)}/documents/${eventType}/${documentId}`;

export const recordTrajectoryQuestionnaireAnswersPath = (
  recordId: string,
  trajectoryId: string,
  eventType: string,
  eventId: string,
  containerId: string,
) => `${recordTrajectoryEventPath(recordId, trajectoryId, eventType, eventId)}/completed/${containerId}`;

export const recordTrajectoryQuestionnaireReportPath = (
  recordId: string,
  trajectoryId: string,
  eventType: string,
  eventId: string,
  containerId: string,
  reportId: string,
) =>
  `${recordTrajectoryQuestionnaireAnswersPath(
    recordId,
    trajectoryId,
    eventType,
    eventId,
    containerId,
  )}/report/${reportId}`;

// add event
export const recordTrajectoryAddSessionPath = (recordId: string, trajectoryId: string) =>
  `${recordTrajectoryPath(recordId, trajectoryId)}/add-session`;
export const recordTrajectoryAddQuestionnairePath = (recordId: string, trajectoryId: string) =>
  `${recordTrajectoryPath(recordId, trajectoryId)}/add-questionnaire`;
export const recordTrajectoryAddQuestionnairePreviewPath = (
  recordId: string,
  trajectoryId: string,
  questionnaire: string,
) => `${recordTrajectoryAddQuestionnairePath(recordId, trajectoryId)}/${questionnaire}`;
export const recordTrajectoryAddQuestionnairePreviewInfoPath = (
  recordId: string,
  trajectoryId: string,
  questionnaire: string,
) => `${recordTrajectoryAddQuestionnairePreviewPath(recordId, trajectoryId, questionnaire)}/info`;
export const recordTrajectoryAddQuestionnairePreviewQuestionsPath = (
  recordId: string,
  trajectoryId: string,
  questionnaire: string,
) => `${recordTrajectoryAddQuestionnairePreviewPath(recordId, trajectoryId, questionnaire)}/questions`;

export const therapistRecordTrajectoryUncompletedQuestionnairePath = (
  recordId,
  trajectoryId,
  eventId,
  questionnaireId,
) =>
  `${recordTrajectoryEventPath(recordId, trajectoryId, EventUrlEnum.session, eventId)}/uncompleted/${questionnaireId}`;

export const recordTrajectoryAddSyncPath = (recordId: string, trajectoryId: string) =>
  `${recordTrajectoryPath(recordId, trajectoryId)}/add-sync`;
export const recordTrajectoryAddTaskPath = (recordId: string, trajectoryId: string) =>
  `${recordTrajectoryPath(recordId, trajectoryId)}/add-task`;
export const recordTrajectoryAddModulePath = (recordId: string, trajectoryId: string) =>
  `${recordTrajectoryPath(recordId, trajectoryId)}/add-module`;

export const recordTrajectoryNotesPath = (recordId: string, trajectoryId: string) =>
  `${recordTrajectoryPath(recordId, trajectoryId)}/notes`;
export const recordTrajectoryStatisticsPath = (recordId: string, trajectoryId: string) =>
  `${recordTrajectoryPath(recordId, trajectoryId)}/statistics`;
export const recordDetailsPath = (recordId: string) => `${recordPath(recordId)}/details`;
export const recordPersonalDetailsPath = (recordId: string) => `${recordDetailsPath(recordId)}/personal`;
export const recordTreatmentDetailsPath = (recordId: string) => `${recordDetailsPath(recordId)}/treatment`;
export const recordRegistrationPath = (recordId: string) => `${recordDetailsPath(recordId)}/registration`;
export const recordRegistrationDetailsPath = (recordId: string, intakeId: string) =>
  `${recordDetailsPath(recordId)}/registration/${intakeId}`;

export const recordAffiliatesPath = (recordId: string) => `${recordDetailsPath(recordId)}/affiliates`;
export const recordAffiliatesAddPath = (recordId: string) => `${recordAffiliatesPath(recordId)}/add`;
export const recordAffiliatesEditPath = (recordId: string, affiliateId: string) =>
  `${recordAffiliatesPath(recordId)}/${affiliateId}/edit`;

export const recordDocumentsPath = (recordId: string) => `${recordPath(recordId)}/documents`;
export const recordDocumentDetailsPath = (recordId: string, documentId: string) =>
  `${recordDocumentsPath(recordId)}/${documentId}`;
export const recordAddDocumentPath = (recordId: string) => `${recordDocumentsPath(recordId)}/add`;
export const recordEditDocumentPath = (recordId: string, documentId: string) =>
  `${recordDocumentsPath(recordId)}/edit/${documentId}`;

export const recordNotesPath = (recordId: string) => `${recordPath(recordId)}/notes`;
export const recordNotesTrajectoryPath = (recordId: string) => `${recordPath(recordId)}/notes/trajectory`;
export const recordNotesPersonalPath = (recordId: string) => `${recordPath(recordId)}/notes/personal`;

export const recordSettingsPath = (recordId: string) => `${recordPath(recordId)}/settings`;
export const recordTrajectoryEndPath = (recordId: string, trajectoryId: string) =>
  `${recordSettingsPath(recordId)}/${trajectoryId}/end`;

// TEMPLATES
export const templatesPath = `${libraryPath}/templates`;
export const templatesAddPath = `${templatesPath}/add`;

export const templatePath = (template) => `${templatesPath}/${template}`;

export const templateEditPath = (template) => `${templatePath(template)}/edit`;

export const templateDuplicatePath = (template) => `${templatePath(template)}/duplicate`;

// QUESTIONNAIRE
export const questionnairesPath = `${libraryPath}/questionnaires`;
export const withQueryQuestionnairesPath = (query) => `${questionnairesPath}${query}`;
export const questionnairesAddPath = `${questionnairesPath}/add`;

export const questionnairePath = (questionnaire) => `${questionnairesPath}/${questionnaire}`;

export const questionnairePathInfo = (questionnaire) => `${questionnairePath(questionnaire)}/info`;

export const questionnairePathQuestions = (questionnaire) => `${questionnairePath(questionnaire)}/questions`;

export const questionnaireEditPath = (questionnaire) => `${questionnairePath(questionnaire)}/edit`;

export const questionnaireDuplicatePath = (questionnaire) => `${questionnairePath(questionnaire)}/duplicate`;

// EXERCISES
export const exercisesPath = `${libraryPath}/exercises`;
export const withQueryExercisesPath = (query) => `${exercisesPath}${query}`;
export const exercisePath = (exercise) => `${exercisesPath}/${exercise}`;
export const exerciseEditPath = (exercise) => `${exercisePath(exercise)}/edit`;
export const exerciseAddPath = `${exercisesPath}/add`;

// REPORTS
export const reportsPath = applyBaseUrl('reports');
export const reportsAddPath = `${reportsPath}/add`;

export const reportPath = (report) => `${reportsPath}/${report}`;

export const reportGeneratePath = (report) => `${reportPath(report)}/generate`;

export const reportDatePath = (report, date) => `${reportPath(report)}/date/${date}`;

export const reportEditPath = (report) => `${reportPath(report)}/edit`;

export const reportDuplicatePath = (report) => `${reportPath(report)}/duplicate`;
// SETTINGS
export const settingsPath = applyBaseUrl('settings');

export const settingsOrganizationPath = `${settingsPath}/general`;

export const settingsBillingPath = `${settingsPath}/billing`;

export const settingsMemberDetailsPath = (member) => `${settingsOrganizationPath}/details/${member}`;

export const settingsAddMemberPath = `${settingsOrganizationPath}/add`;

// CALENDAR
export const calendarPath = applyBaseUrl('calendar');
export const calendarAddSessionPath = `${calendarPath}/add-session`;
export const calendarAddCustomEventPath = `${calendarPath}/add-custom-event`;
export const calendarSettingsPath = `${calendarPath}/settings`;
export const calendarEventPath = (type: string, eventId: string) => `${calendarPath}/event/${type}/${eventId}`;

// TRAJECTORIES
export const protocolsPath = `${libraryPath}/protocols`;
export const withQueryProtocolsPath = (query) => `${protocolsPath}${query}`;

export const protocolPath = (protocol) => `${protocolsPath}/${protocol}`;
export const protocolSessionsPath = (protocol) => `${protocolsPath}/${protocol}/sessions`;

export const protocolQuestionnaireInfoPath = (protocol, interaction, questionnaire) =>
  `${protocolPath(protocol)}/interactions/${interaction}/questionnaires/${questionnaire}/info`;

export const protocolQuestionnaireQuestionsPath = (protocol, interaction, questionnaire) =>
  `${protocolPath(protocol)}/interactions/${interaction}/questionnaires/${questionnaire}/questions`;

// MODULES
export const modulesPath = `${libraryPath}/modules`;
export const modulesDetailsPath = (moduleId: string) => `${modulesPath}/${moduleId}`;
export const modulesBlendedCarePath = `${modulesPath}/blended-care`;

// SESSIONS OVERVIEW
export const sessionsOverviewPath = applyBaseUrl('sessions-overview');

// CHAT
export const chatPath = applyBaseUrl('chat');
export const chatRoomPath = (chatRoomId: string) => `${chatPath}/${chatRoomId}`;

// eHealthBox
export const eHealthBoxPath = applyBaseUrl('ehealthbox');

// Todo
export const todoPath = applyBaseUrl('todo');
export const recordTodoPath = (recordId: string) => `${recordPath(recordId)}/todo`;

// FORBIDDEN

export const forbiddenErrorPath = applyBaseUrl(`forbidden`);

// INTAKE

export const intakesPath = applyBaseUrl('intakes');
export const intakeDetailsPath = (intakeId: string) => `${intakesPath}/${intakeId}`;
export const intakeDetailsDocumentPath = (intakeId: string, documentId: string) =>
  `${intakeDetailsPath(intakeId)}/document/${documentId}`;
export const intakeDetailsEditPath = (intakeId: string) => `${intakeDetailsPath(intakeId)}/edit`;
export const intakeDetailsActivityPath = (intakeId: string) => `${intakeDetailsPath(intakeId)}/activity`;
export const intakeDetailsCommentsPath = (intakeId: string) => `${intakeDetailsPath(intakeId)}/comments`;
export const intakeDetailsDocumentsPath = (intakeId: string) => `${intakeDetailsPath(intakeId)}/documents`;
export const intakesSettingsPath = `${intakesPath}/settings`;
export const intakesPermissionsPath = `${intakesPath}/permissions`;
export const intakesPermissionsMemberPath = (memberId: string) => `${intakesPermissionsPath}/member/${memberId}`;
export const intakesAddClientPath = `${intakesPath}/add`;
export const intakeFormEditPagePath = `${intakesPath}/settings/edit`;
export const intakeFormPreviewPath = `${intakesPath}/settings/preview`;
export const intakeImportPath = `${intakesPath}/import`;
