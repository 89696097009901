export const baseAdminPath = '/admin';

const applyBaseUrl = (url) => `${baseAdminPath}/${url}`;

// Profile
export const profilePath = applyBaseUrl('profile');

// ORGANIZATIONS

export const organizationsPath = applyBaseUrl('organizations');

export const organizationPath = (orgId) => `${organizationsPath}/${orgId}`;
export const organizationDetailPath = (orgId) => `${organizationsPath}/${orgId}/info `;
export const organizationUsagesPath = (orgId) => `${organizationsPath}/${orgId}/usages`;

export const organizationsStatusPath = (orgStatus: string) => `${organizationsPath}/${orgStatus}`;

export const organizationDetailAddMemberPath = (orgId) => `${organizationDetailPath(orgId)}/add-member`;
export const organizationsMemberPath = (orgId, memberId) => `${organizationDetailPath(orgId)}/member/${memberId}`;

// THERAPISTS

export const therapistsPath = applyBaseUrl('therapists');

export const therapistsDetailPath = (therapistId) => `${therapistsPath}/${therapistId}`;

export const therapistsAddPath = `${therapistsPath}/add`;

// CLIENTS

export const clientsPath = applyBaseUrl('clients');

// TRAJECTORIES

export const protocolsPath = applyBaseUrl('protocols');

export const protocolsAddPath = `${protocolsPath}/add`;

export const protocolPath = (protocol) => `${protocolsPath}/${protocol}`;

export const protocolSessionsPath = (protocol) => `${protocolPath(protocol)}/sessions`;
export const protocolEditPath = (protocol) => `${protocolPath(protocol)}/edit`;

export const protocolEditActivePath = (protocol) => `${protocolPath(protocol)}/edit-active`;

export const protocolDuplicatePath = (protocol) => `${protocolPath(protocol)}/duplicate`;

// QUESTIONNAIRY
export const questionnairesPath = applyBaseUrl('questionnaires');

export const questionnairesAddPath = `${questionnairesPath}/add`;

export const questionnairesTagsPath = `${questionnairesPath}/tags`;

export const questionnairesPublishersPath = `${questionnairesPath}/publishers`;

export const questionnairesGroupsPath = `${questionnairesPath}/groups`;
export const questionnairesGroupDetailsPath = (groupId: string) => `${questionnairesGroupsPath}/${groupId}`;

export const questionnairePath = (questionnaire) => `${questionnairesPath}/${questionnaire}`;

export const questionnairePathInfo = (questionnaire) => `${questionnairePath(questionnaire)}/info`;

export const questionnairePathQuestions = (questionnaire) => `${questionnairePath(questionnaire)}/questions`;

export const questionnaireEditPath = (questionnaire) => `${questionnairePath(questionnaire)}/edit`;

export const questionnaireDuplicatePath = (questionnaire) => `${questionnairePath(questionnaire)}/duplicate`;

// REPORTS
export const reportsPath = applyBaseUrl('reports');
export const reportsAddPath = `${reportsPath}/add`;

export const reportTestPath = `${reportsPath}/tests`;

export const reportPath = (report) => `${reportsPath}/${report}`;

export const reportGeneratePath = (report) => `${reportPath(report)}/generate`;

export const reportDatePath = (report, date) => `${reportPath(report)}/date/${date}`;

export const reportEditPath = (report) => `${reportPath(report)}/edit`;

export const reportDuplicatePath = (report) => `${reportPath(report)}/duplicate`;

// EXERCISES
export const exercisesPath = applyBaseUrl('exercises');

export const exercisesAddPath = `${exercisesPath}/add`;

export const exercisePath = (exercise) => `${exercisesPath}/${exercise}`;

export const exerciseEditPath = (exercise) => `${exercisePath(exercise)}/edit`;

// SETTINGS
export const settingsPath = applyBaseUrl('settings');

// SETTINGS/notifications
export const settingsNotificationsPath = `${settingsPath}/notifications`;

export const settingsNotificationsAddPath = `${settingsNotificationsPath}/add`;

export const settingsNotificationPath = (settingsNotification) =>
  `${settingsNotificationsPath}/${settingsNotification}`;

export const settingsNotificationEditPath = (settingsNotification) =>
  `${settingsNotificationPath(settingsNotification)}/edit`;

export const settingsNotificationDuplicatePath = (settingsNotification) =>
  `${settingsNotificationPath(settingsNotification)}/duplicate`;

// SETTINGS/terms-conditions
export const settingsTermsPath = `${settingsPath}/terms`;

export const settingsTermsAddPath = `${settingsTermsPath}/add`;

export const settingsTermPath = (term) => `${settingsTermsPath}/${term}`;

export const settingsTermEditPath = (term) => `${settingsTermPath(term)}/edit`;

export const settingsTermDuplicatePath = (term) => `${settingsTermPath(term)}/duplicate`;

// Templates
export const templatesPath = applyBaseUrl('templates');

export const templatesAddPath = `${templatesPath}/add`;

export const templatePath = (template) => `${templatesPath}/${template}`;

export const templateEditPath = (template) => `${templatePath(template)}/edit`;

export const templateDuplicatePath = (template) => `${templatePath(template)}/duplicate`;

// MODULES
export const modulesPath = applyBaseUrl('modules');
