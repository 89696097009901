import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganisationPrices, useProfile } from 'data/hooks';
import { OrganizationPaymentType, RoleEnum } from 'data/enums';
import { TFunction } from 'i18next';
import { settingsBillingPath } from 'app/constants/url/therapist';
import { useHistory } from 'react-router';
import { doc, DocumentData, getDoc } from 'firebase/firestore';
import { PROD } from 'app/constants/env';
import { useBillingPage } from 'app/components/app/therapist/settings/billing/hooks/useBillingPage';
import { useHasRole } from '../hooks';
import { DigitalAddonContext, DigitalAddonContextInterface } from '.';
import { db } from '../../../fire/config';
import Locale from 'data/enums/Locale';

export const fetchReleaseDates = async () => {
  const docRef = doc(db, PROD ? 'PROD_CONFIGURATION' : 'STAGING_CONFIGURATION', 'digital_waiting_room_addon');
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
    console.log('No such document!');
    return null;
  } catch (error) {
    console.log('Error fetching dates: ', error);
  }
};

export interface DigitalAddonProviderProps {
  children: ReactNode;
}

const getBannerText = (
  t: TFunction<'translation', undefined>,
  isTrialEnded: boolean,
  paysForIntakes: boolean | undefined,
  paidAddonReleaseDate: Date,
  addonWarningReleaseDate: Date,
  currentDate: Date,
  isCustomPayment: boolean,
  isOrganisationAdmin: boolean,
) => {
  if (paysForIntakes !== undefined && !!addonWarningReleaseDate && addonWarningReleaseDate < currentDate) {
    if (!paysForIntakes && isTrialEnded && !isCustomPayment) {
      if (paidAddonReleaseDate > currentDate) {
        return isOrganisationAdmin
          ? t('yourOrganisationDoesntHaveDigitalAddonWarningOrgAdmin')
          : t('yourOrganisationDoesntHaveDigitalAddonWarningCaretaker');
      }
      return t('yourOrganisationDoesntHaveDigitalAddon');
    }
    return null;
  }
  return null;
};

const getBannerButtonText = (
  t: TFunction<'translation', undefined>,
  isOrganisationAdmin: boolean,
  isTrialEnded: boolean,
  paysForIntakes: boolean | undefined,
  isCustomPayment: boolean,
) => {
  if (!paysForIntakes && isTrialEnded && isOrganisationAdmin && !isCustomPayment) {
    return t('comparePlans');
  }
  return null;
};

const DigitalAddonProvider = ({ children }: DigitalAddonProviderProps) => {
  const history = useHistory();

  const {
    t,
    i18n: { getFixedT },
  } = useTranslation();

  const nlT = getFixedT(Locale.DUTCH, null);

  const { profile } = useProfile();
  const isOrganisationAdmin = useHasRole(RoleEnum.ROLE_ORGANISATION_ADMIN);
  const [dates, setDates] = useState<DocumentData | null | undefined>(null);
  const paidAddonReleaseDate = dates?.paidAddonReleaseDate.toDate() ?? ''; // when Addon should be added
  const addonWarningReleaseDate = dates?.addonWarningReleaseDate.toDate() ?? ''; // when initial warning should be shown
  const currentDate = new Date();

  const { data: prices } = useOrganisationPrices(profile.organisation?.id || '');

  const getDates = async () => {
    const data = await fetchReleaseDates();
    setDates(data);
  };

  useEffect(() => {
    getDates();
  }, []);

  const isCustomPayment = useMemo(
    () => profile?.organisation?.paymentType === OrganizationPaymentType.CUSTOM,
    [profile?.organisation?.paymentType],
  );

  const { isPremiumPlan, organisationAddons } = useBillingPage(!isOrganisationAdmin);

  const isIntakeAddon =
    useMemo(
      () =>
        organisationAddons?.find((addon) => {
          return addon.name == nlT('addons.digital.title', { returnObjects: true }).toString();
        }),
      [organisationAddons?.length],
    ) ?? false;

  const isTrialEnded = useMemo(
    () => (profile?.organisation?.trialEndAt ? new Date() > new Date(profile?.organisation?.trialEndAt) : true),
    [profile?.organisation?.trialEndAt],
  );

  const paydIntakeAdminCondition = !!isPremiumPlan || !!isIntakeAddon;

  const paydForIntakesAddon = useMemo(
    () => (isOrganisationAdmin ? paydIntakeAdminCondition : prices?.paysForIntakes),
    [prices?.paysForIntakes, isPremiumPlan, isIntakeAddon],
  );

  const bannerText = useMemo(
    () =>
      getBannerText(
        t,
        isTrialEnded,
        paydForIntakesAddon,
        paidAddonReleaseDate,
        addonWarningReleaseDate,
        currentDate,
        isCustomPayment,
        isOrganisationAdmin,
      ),
    [t, isTrialEnded, paydForIntakesAddon, isCustomPayment, dates],
  );

  const bannerButtonText = useMemo(
    () => getBannerButtonText(t, isOrganisationAdmin, isTrialEnded, paydForIntakesAddon, isCustomPayment),
    [t, isOrganisationAdmin, isTrialEnded, paydForIntakesAddon, isCustomPayment],
  );

  const bannerHandleClick = () => {
    history.push(settingsBillingPath);
  };

  const isBlockDigitalWaitingRoom = useMemo(
    () => !paydForIntakesAddon && isTrialEnded && currentDate > paidAddonReleaseDate && !isCustomPayment,
    [paydForIntakesAddon, isTrialEnded, currentDate, paidAddonReleaseDate, isCustomPayment, dates],
  );

  const value: DigitalAddonContextInterface = useMemo(
    () => ({
      bannerText,
      bannerButtonText,
      bannerHandleClick,
      isBlockDigitalWaitingRoom,
    }),
    [bannerText, bannerButtonText, bannerHandleClick, isBlockDigitalWaitingRoom],
  );

  return <DigitalAddonContext.Provider value={value}>{children}</DigitalAddonContext.Provider>;
};

export default DigitalAddonProvider;
